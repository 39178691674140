<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card-action title="Filter" :badge="$store.getters['filter/isItemsFiltered'] ? 'Filtered':''" collapsed>
        <c-form ref="filterForm" @submit="filterSubmit">
          <form-input v-model="itemsFilter.code" label="Kode"></form-input>
          <div class="d-flex justify-content-between">
            <form-date :max="itemsFilter.date_2" class="w-100 mr-1" v-model="itemsFilter.date_1" label="Periode Awal"></form-date>
            <form-date :min="itemsFilter.date_1" class="w-100" v-model="itemsFilter.date_2" label="Periode Akhir"></form-date>
          </div>
          <form-select-all multiple v-model="itemsFilter.category" label="Kategori Produk" endpoint="v1/item_categories/selectkat" nested></form-select-all>
          <form-select-all multiple v-model="itemsFilter.brand" label="Merek" endpoint="v1/brands/select2"></form-select-all>
          <form-select-static v-if="!onlyActiveFilter" clearable :options="statuses" v-model="itemsFilter.status" label="Status"></form-select-static>
          <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
          <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
        </c-form>
      </c-card-action>
    </b-col>
    <b-col sm="12">
      <c-card title="Daftar">
        <template slot="action">
          <b-button :to="{name:'items-create'}" variant="primary"><feather-icon icon="PlusIcon"></feather-icon> Tambah</b-button>
        </template>
        <div>
          <div class="d-flex justify-content-between mb-1">
            <div>
              <small class="padding">Tampilkan </small>
              <label>
                <b-form-select
                  @input="getData()"
                  v-model="pageLength"
                  :options="lengthArray"
                />
              </label>
              <small class="padding"> Entri</small>
            </div>
            <div class="w-25">
              <b-input-group>
                <b-form-input @input="getData()" v-model="search" type="search" placeholder="Cari..."></b-form-input>
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon"></feather-icon>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <div v-if="isloading" class="text-center mt-1 mb-1">
            <b-spinner variant="primary" type="grow"></b-spinner>
          </div>
          <b-table-simple v-else responsive outlined hover small>
            <b-thead>
              <b-tr>
                <b-th>No. Penerimaan</b-th>
                <b-th v-if="isModal" width="5"></b-th>
                <b-th class="text-center">Merek</b-th>
                <b-th>Kategori</b-th>
                <b-th>Kode Variasi</b-th>
                <b-th>Variasi</b-th>
                <b-th>Harga</b-th>
                <b-th>Qty</b-th>
              </b-tr>
            </b-thead>
            <b-tbody class="fixed-height">
              <template v-for="(value,key) in items">
                <b-tr :key="key">
                  <b-td class="w-25" :rowspan="value.details.length+1">
                    <div class="">
                      <b-form-checkbox v-if="isModal" class="mb-1" :checked="false" @change="oncheckvariant($event,key)">Pilih Semua</b-form-checkbox>
                      <h5 class="font-weight-bolder">{{value.code}}</h5>
                      <h6 class="font-weight-bolder">{{value.date}} - {{value.grand_total | formatNumber}}</h6>
                      <h6>{{value.contact_name}}</h6>
                      <span class="small font-underline">Deskripsi :</span>
                      <p class="small">{{value.note}}</p>
                    </div>
                  </b-td>
                  <b-td v-if="isModal">
                    <b-form-checkbox v-model="value.details[0].checked"></b-form-checkbox>
                  </b-td>
                  <b-td class="small">1. {{value.details[0].brand_name}}</b-td>
                  <b-td class="small">{{value.details[0].category_name}}</b-td>
                  <b-td class="small">{{value.details[0].code_barcode}}</b-td>
                  <b-td class="small">{{value.details[0].size_name}}, {{value.details[0].color_name}}</b-td>
                  <b-td class="small">Rp.{{value.details[0].price | formatNumber}}</b-td>
                  <b-td class="small">{{value.details[0].qty | formatNumber}}</b-td>
                </b-tr>
                <b-tr v-show="k>0" v-for="(detail,k) in value.details" :key="'vr'+detail.id_reception_size">
                  <b-td v-if="isModal">
                    <b-form-checkbox v-model="detail.checked"></b-form-checkbox>
                  </b-td>
                  <b-td class="small">{{k+1}}. {{detail.brand_name}}</b-td>
                  <b-td class="small">{{detail.category_name}}</b-td>
                  <b-td class="small">{{detail.code_barcode}}</b-td>
                  <b-td class="small">{{detail.size_name}}, {{detail.color_name}}</b-td>
                  <b-td class="small">Rp.{{detail.price | formatNumber}}</b-td>
                  <b-td class="small">{{detail.qty | formatNumber}}</b-td>
                </b-tr>
                <b-tr v-if="!value.is_appended" @click="appendVariant(key)" :key="`adds`+key">
                  <b-td colspan="7" class="text-center">
                    <b-link class="small font-italic">Show More...</b-link>
                  </b-td>
                </b-tr>
                <b-tr v-else @click="prependVariant(key)" :key="`less${key}`">
                  <b-td colspan="7" class="text-center">
                    <b-link class="small font-italic">Show Less...</b-link>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
          <div class="d-flex justify-content-between">
            <span class="padding small">Menampilkan {{items.length}} dari {{total}} data.</span>
            <b-pagination v-model="page" :per-page="pageLength" :total-rows="total" ></b-pagination>
          </div>
        </div>
      </c-card>
      <div v-if="isModal" class="d-flex justify-content-end">
        <b-button variant="primary" @click="importModalExe">Import</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import {BPagination,BLink,BButton,BFormCheckbox,BInputGroup,BInputGroupAppend,BFormSelect,BFormInput,BTableSimple,BThead,BTr,BTh,BTbody,BTd,BSpinner} from 'bootstrap-vue'
import CCardAction from '@/utils/components/CCardAction.vue'
import CForm from '@/utils/components/CForm.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormInput from '@/utils/components/FormInput.vue'
import { mapFields } from 'vuex-map-fields'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import map from 'lodash/map'
import join from 'lodash/join'
import FormSelectStatic from '@/utils/components/FormSelectStatic.vue'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import filter from 'lodash/filter'

export default {
  props:{
    isModal:Boolean,
    onlyActiveFilter:Boolean,
    contactId:Number
  },
  components:{
    CCard,
    BButton,
    CCardAction,
    CForm,
    FormInput,
    FormSelectAll,
    FormSelectStatic,
    BLink,
    BInputGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BInputGroupAppend,
    BPagination,
    BSpinner,
    FormDate
  },
  data(){
    const statuses = [
      {value:1,label:'Aktif'},
      {value:0,label:'Non-Aktif'},
    ]
    const stockTerms = [
      {value:'eq',label:'='},
      {value:'ne',label:'!='},
      {value:'gt',label:'>'},
      {value:'gte',label:'>='},
      {value:'lt',label:'<'},
      {value:'lte',label:'<='},
    ]
    const items = []
    const lengthArray = [
      10,
      25,
      50,
      100
    ]
    return {
      statuses,
      stockTerms,
      items,
      pageLength: 10,
      lengthArray,
      search:'',
      total:0,
      page:1,
      total_pages:1,
      sortKey:'',
      sortDesc:false,
      isloading:true
    }
  },
  computed:{
    ...mapFields('filter',['itemsFilter']),
    filterParams(){
      const dt = JSON.parse(JSON.stringify(this.itemsFilter))
      let fd = {}
      fd.date_1 = dt.date_1
      fd.date_2 = dt.date_2
      if(dt.code) fd.code = dt.code
      if(dt.category.length>0) fd.id_category = join(map(dt.category,n => n.value),',')
      if(dt.brand.length>0) fd.id_brand = join(map(dt.brand,n => n.value),',')
      if(dt.stock_term) fd.stock_term = dt.stock_term
      if(dt.stock) fd.stock = dt.stock
      if(dt.status) fd.id_status = dt.status.value
      return fd
    },
    endpoint(){
      return 'v1/receptions/modal'
    }
  },
  watch:{
    page(){
      const vm = this
      vm.getData()
    },
    pageLength(){
      const vm = this
      vm.page = 1
      vm.getData()
    }
  },
  methods:{
    filterSubmit(){
      this.getData()
    },
    resetFilter(){
      const vm = this
      vm.$store.commit('filter/RESET_ITEMS')
      vm.$nextTick(()=>{
        this.getData()
      })
    },
    importModalExe(){
      const data = this.items
      this.$emit('importingfn', data)
      this.$nextTick(()=>{
        this.resetFilter()
      })
    },
    async getData(){
      this.onLoad(this)
    },
    onLoad: debounce((vm) => {
      vm.isloading = true
      let endpoint = vm.endpoint
      const params = {
        search: vm.search,
        page: vm.page,
        per_page: vm.pageLength,
        sort: vm.sortKey,
        order: vm.sortDesc ? 'desc':'asc',
        id_contact: vm.contactId,
        ...vm.filterParams
      }
      vm.$http.get(endpoint,{params:params}).then(e => {
        const {data,total,total_pages} = e.data
        vm.items = data
        vm.total = total
        vm.total_pages = total_pages
        vm.isloading = false
      }).catch(()=>{
        vm.isloading = false
      })
    },200),
    async appendVariant(key){
      const {id} = this.items[key]
      const params = JSON.parse(JSON.stringify(this.filterParams))
      params.id = id
      params.page = 2
      params.sort = params.sort ? params.sort : "",
      params.search = params.search ? params.search : ""

      const {data} = await this.$http.get(`v1/receptions/detail`,{params,...this.filterParams})
      const items = JSON.parse(JSON.stringify(this.items[key].details))
      let mapping = []
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        mapping.push({...element,is_add: true})
      }
      this.items[key].details = uniqBy([...items,...mapping],'id_reception_size')
      this.items[key].is_appended = true
    },
    prependVariant(key){
      const dt = JSON.parse(JSON.stringify(this.items[key].details))
      this.items[key].details = filter(dt,n => (!n.is_add))
      this.items[key].is_appended = false
    },
    oncheckvariant(value,key) {
      const fr = JSON.parse(JSON.stringify(this.items[key].details))
      const changed = map(fr,n => {
        if(value) return {...n,checked:true}
        else return {...n,checked:false}
      })
      this.items[key].details = changed
    }
  },
  mounted(){
    this.getData()
  },
  created(){
    if(this.onlyActiveFilter) this.itemsFilter.status = {value:1,label:"Aktif"}
  }
}
</script>

<style lang="scss">
  p.nowrap {
    white-space: nowrap;
  }
  .fixed-height tr > td {
    height: 2px;
  }
</style>