<template>
  <div>
    <c-card title="Form">
      <template #action>
        <c-button :to="{name:'purchasereturns'}" variant="outline-primary">Kembali</c-button>
        <c-button @click="$refs.form.onSubmit()" :loading="buttonloading">Simpan</c-button>
      </template>
      <c-form ref="form" @submit="confirmSubmit">
        <b-row cols-sm="2" cols-xl="4">
          <b-col>
            <form-input readonly label="No Nota" placeholder="Generate Otomatis" v-model="code"></form-input>
          </b-col>
          <b-col>
            <form-date label="Tanggal" v-model="formData.date" :rules="{required: true}"></form-date>
          </b-col>
          <b-col>
            <form-select-all @option:selected="details=[]" label="Pemasok" v-model="supplier" :rules="{required: true}" endpoint="v1/contacts/select2"></form-select-all>
          </b-col>
          <b-col>
            <c-button :disabled="!selectedContactId" @click="$refs.itemModal.show()" class="mt-2" block><feather-icon icon="SearchIcon"></feather-icon> Cari Produk</c-button>
          </b-col>
        </b-row>
        <b-table-simple table-class="text-center" class="mt-2 mb-2" fixed small outlined>
          <b-thead>
            <b-tr>
              <b-th rowspan="2">Merek</b-th>
              <b-th rowspan="2">Artikel</b-th>
              <b-th rowspan="2">Warna</b-th>
              <b-th rowspan="2">Harga</b-th>
              <b-th :colspan="sizeRows.length||1">Ukuran</b-th>
              <b-th rowspan="2">Jml</b-th>
              <b-th rowspan="2">Total</b-th>
              <b-th rowspan="2"></b-th>
            </b-tr>
            <b-tr>
              <b-th v-for="(v,k) in sizeRows" :key="k">{{v.size_name}}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(v,i) in details" :key="i">
              <b-td>{{v.brand_name}}</b-td>
              <b-td>{{v.reception_code}}</b-td>
              <b-td>{{v.color_name}}</b-td>
              <b-td>
                <inline-input-number @onValueChanged="qtySizeChanged(i)" v-model.number="v.price"></inline-input-number>
              </b-td>
              <b-td v-for="(x,k) in sizeRows" :key="k">
                <template v-for="(s,l) in v.sizes">
                  <inline-input-number @onValueChanged="qtySizeChanged(i)" v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l" v-model.number="s.qty"></inline-input-number>
                </template>
              </b-td>
              <b-td>{{v.qty_total}}</b-td>
              <b-td>Rp.{{v.price_total | formatNumber}}</b-td>
              <b-td>
                <c-button size="sm" variant="outline-danger" @click="rowDelete(i)">X</c-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-row>
          <b-col sm="12" xl="5">
            <form-textarea v-model="formData.note" label="Catatan"></form-textarea>
          </b-col>
          <b-col sm="12" xl="3" offset-xl="4">
            <form-number :rules="{minimal:1}" disabled class="w-50" :value="subTotal" label="Sub Total"></form-number>
            <div class="d-flex justify-content-between">
            <form-number :rules="{required: true}" @onChange="discPercentChange" class="w-50" v-model.number="formData.disc_percent" label="Disc %"></form-number>
            <form-number :rules="{required: true}" @onChange="discTotalChange" class="w-100 ml-1" v-model.number="formData.disc_total" label="Disc Rp."></form-number>
            </div>
            <form-number :rules="{minimal:1}" disabled class="w-50" :value="grandTotal" label="Grand Total"></form-number>
          </b-col>
        </b-row>
        <button type="submit" hidden></button>
      </c-form>
    </c-card>
    <b-modal lazy ref="itemModal" hide-footer size="xl">
      <ReceiptIndex :contact-id="selectedContactId" only-active-filter is-modal @importingfn="importing" />
    </b-modal>
  </div>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import CButton from '@/utils/components/CButton.vue'
import CForm from '@/utils/components/CForm.vue'
import {BTableSimple,BThead,BTr,BTh,BModal,BTbody,BTd} from 'bootstrap-vue'
import FormInput from '@/utils/components/FormInput.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
import FormNumber from '@/utils/components/FormNumber.vue'
import ReceiptIndex from './ReceiptIndex.vue'

import findIndex from 'lodash/findIndex'
import flatMap from 'lodash/flatMap'
import sumBy from 'lodash/sumBy'
import sortBy from 'lodash/sortBy'
import InlineInputNumber from '@/utils/components/InlineInputNumber.vue'
import ScannerDetector from 'js-scanner-detection'

export default {
  components: { CCard, CButton, CForm, FormInput, FormDate, FormSelectAll, BTableSimple,BThead,BTr,BTh, FormTextarea, FormNumber, BModal, ReceiptIndex, BTbody, BTd, InlineInputNumber },
  data(){
    const onComplete = (barcode) => {
      this.barcodeScanned(barcode)
    }
    const options = {
      onComplete,
      stopPropagation: true,
      preventDefault: false,
    }

    const formData = {
      date: this.$moment(),
      note: '',
      id_contact: null,
      sub_total: 0,
      disc_percent: 0,
      disc_total: 0,
      grand_total: 0
    }
    return {
      formData,
      code:'',
      supplier:null,
      buttonloading: false,
      details:[],
      scannerDetect : new ScannerDetector(options),
    }
  },
  computed:{
    sizeRows(){
      const dt = flatMap(this.details,n => n.sizes)
      let result = []
      for (let i = 0; i < dt.length; i++) {
        const el = dt[i];
        if(findIndex(result,{id_size: el.id_size})<0)
          result.push({
            id_size: el.id_size,
            size_name: el.size_name,
          })
      }
      return sortBy(result,['size_name'])
    },
    subTotal(){
      const total = sumBy(this.details,'price_total')
      return total
    },
    qtyTotal(){
      const total = sumBy(this.details,'qty_total')
      return total
    },
    grandTotal(){
      const {disc_total} = this.formData
      const subTotal = this.subTotal
      return subTotal-parseFloat(disc_total)
    },
    selectedContactId(){
      if(this.supplier) return this.supplier.value
      else return null
    },
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    async submitForm(){
      const vm = this
      vm.buttonloading = true
      const formData = JSON.parse(JSON.stringify(vm.formData))
      formData.details = vm.details
      formData.qty_total = vm.qtyTotal
      formData.sub_total = vm.subTotal
      formData.grand_total = vm.grandTotal
      formData.id_contact = vm.supplier.value
      if(this.$route.params.id) {
        this.$http.put(`v1/purchase_returns/update`,formData,{params:{id:this.$route.params.id,code:this.code}}).then(() => {
          vm.notify(`Data Berhasil Disimpan!`)
          vm.$router.push({name:'purchasereturns'})
          vm.buttonloading = false
        }).catch((error) => {
          vm.buttonloading = false
          vm.handleError(error)
        })
      } else {
        this.$http.post(`v1/purchase_returns/create`,formData).then(() => {
          vm.notify(`Data Berhasil Disimpan!`)
          vm.$router.push({name:'purchasereturns'})
          vm.buttonloading = false
        }).catch((error) => {
          vm.buttonloading = false
          vm.handleError(error)
        })
      }
    },
    async getData(){
      const {data} = await this.$http.get(`v1/purchase_returns/show`,{params:{id:this.$route.params.id}})
      this.details = data.details
      const dt = JSON.parse(JSON.stringify(data))
      delete dt.details
      this.formData = dt
      this.supplier = {value:dt.id_contact, label:dt.contact_name}
      this.code = dt.code
    },
    importing(data){
      let imported = JSON.parse(JSON.stringify(this.details))
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        // let itemreception = []
        for (let x = 0; x < item.details.length; x++) {
          const variant = item.details[x];
          if(variant.checked) {
            // console.log(variant)
            if(findIndex(imported,{id_reception: item.id, id_reception_detail: variant.id_reception_detail})<0) {
              // itemreception.push(variant.id_reception_detail)
              imported.push({
                id_reception: item.id,
                id_reception_detail: variant.id_reception_detail,
                reception_code: item.code,
                id_item: variant.id_item,
                id_brand: variant.id_brand,
                brand_name: variant.brand_name,
                id_color: variant.id_color,
                color_name: variant.color_name,
                price: variant.price,
                qty_total: variant.qty,
                price_total: variant.qty * variant.price,
                sizes:[
                  {
                    id_reception_size: variant.id_reception_size,
                    id_variant: variant.id_variant,
                    id_size: variant.id_size,
                    size_name: variant.size_name,
                    qty: variant.qty
                  }
                ]
              })
            } else {
              const index = findIndex(imported,{id_reception: item.id, id_reception_detail: variant.id_reception_detail})
              if(findIndex(imported[index].sizes,{id_size: variant.id_size})<0) {
                imported[index].sizes.push({
                  id_reception_size: variant.id_reception_size,
                  id_variant: variant.id_variant,
                  id_size: variant.id_size,
                  size_name: variant.size_name,
                  qty: variant.qty
                })
              }
              const vm = this
              vm.$nextTick(()=>{
                vm.qtySizeChanged(index)
              })
            }
          }
        }
      }
      this.details = imported
      this.discPercentChange()
      this.$refs.itemModal.hide()
    },
    qtySizeChanged(key){
      const fd = JSON.parse(JSON.stringify(this.details[key]))
      this.details[key].qty_total = sumBy(fd.sizes,'qty')
      this.details[key].price_total = sumBy(fd.sizes,'qty') * fd.price
      this.discPercentChange()
    },
    discPercentChange(){
      this.formData.disc_total = this.subTotal * this.formData.disc_percent / 100
    },
    discTotalChange(){
      this.formData.disc_percent = this.formData.disc_total / this.subTotal * 100
    },
    rowDelete(key){
      this.details.splice(key, 1)
      this.discPercentChange()
    },
    barcodeScanned(code){
      const id_supplier = this.supplier ? this.supplier.value : null
      // if(!id_supplier) return false
      const vm = this
      this.$http.get(`v1/receptions/findbarcode`,{params:{id_supplier,code}}).then(({data}) => {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          const index = findIndex(vm.details,{id_reception: item.id_reception, id_reception_detail: item.id_reception_detail, id_item: item.id_item})
          if(index>=0) {
            /* EXISTING */
            const indexDetail = findIndex(vm.details[index].sizes,{id_reception_size: item.id_reception_size, id_variant: item.id_variant, id_size: item.id_size})
            if(indexDetail<0) {
              vm.details[index].sizes.push({
                id_reception_size: item.id_reception_size,
                id_variant: item.id_variant,
                id_size: item.id_size,
                size_name: item.size_name,
                qty: item.qty
              })
              vm.$nextTick(()=>{
                vm.qtySizeChanged(index)
              })
            }
          } else {
            /* ADD NEW */
            vm.details.push({
              id_reception: item.id_reception,
              id_reception_detail: item.id_reception_detail,
              reception_code: item.code_reception,
              id_item: item.id_item,
              id_brand: item.id_brand,
              brand_name: item.brand_name,
              id_color: item.id_color,
              color_name: item.color_name,
              price: item.price,
              qty_total: item.qty,
              price_total: item.qty * item.price,
              sizes: [
                {
                  id_reception_size: item.id_reception_size,
                  id_variant: item.id_variant,
                  id_size: item.id_size,
                  size_name: item.size_name,
                  qty: item.qty
                }
              ]
            })
          }
        }
      })
      .catch((error) => {
        this.handleError(error)
      })
    }
  },
  created(){
    if(this.$route.params.id) this.getData()
  }
}
</script>

<style>

</style>